import React from 'react'
// import PropTypes from 'prop-types';
import { Paper, Box, Typography } from '@material-ui/core'

const Help = () => {
    return (
        <>
            <div>
                <Typography variant="h5">手順</Typography>
                <Box py={2}>
                    <Paper elevation={3}>
                        <Box mb={2} p={3}>
                            1. 「入力する」タブに英文をコピペする<br/>
                            2. 「読む」タブに切り替えて、キーボードの右矢印を押す<br/>
                        </Box>
                    </Paper>
                </Box>

                <Typography variant="h5">キーボード操作</Typography>
                <Box py={2}>
                    <Paper elevation={3}>
                        <Box mb={2} p={3}>
                            右矢印: 次に進む<br/>
                            左矢印: 前に戻る <small>(戻り読み防止の為、常に文頭に戻ります)</small><br/>
                            上矢印: 前の文に戻る<br/>
                            下矢印: 次の文に戻る<br/>
                            Enter: 翻訳する<br/>
                        </Box>
                    </Paper>
                </Box>

                <Typography variant="h5">モード切替</Typography>
                <Box py={2}>
                    <Paper elevation={3}>
                        <Box mb={2} p={3}>
                            w: 単語で区切る<small>(デフォルトモード)</small><br/>
                            /: 自分で入れた/ <small>(スラッシュ)</small>で区切る<br/>
                            a: 自動的に区切る<br/>
                        </Box>
                    </Paper>
                </Box>
            </div>
        </>
    )
}

Help.propTypes = {
    // type: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'])
}

export default Help
