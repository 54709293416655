
export const parse = (value, splitter) => {
    let _value = value.replace(/\n/g, ' ')
    _value = _value.replace(/(\.|\?|!)/g, '$1\n')
    const _lines = trimLines(_value.split('\n'))
    const lines = _lines.map(line => {
        let _line = line
        if (splitter) _line = splitter(_line)
        return trimLines(_line.split('/'))
    })
    return lines
}

const trimLines = (lines) => {
    return lines.map(line => line.trim()).filter(line => line)
}

export const wordSplitter = line => line.replace(/([^\s]+)/g, '/$1')
export const aiSplitter = line => line.replace(/\b(from|for|or|as|to|of|in|with)\b/g, '/$1')

export const gotoGoogleTranslate = (value) => {
    const url = `https://translate.google.co.jp/?hl=ja#view=home&op=translate&sl=en&tl=ja&text=${value}`
    const win = window.open(url, '_blank')
    win.focus()
}
