import React, { useState, useEffect } from 'react'
import useEventListener from '../hooks/useEventHandler'
import { parse, wordSplitter, aiSplitter, gotoGoogleTranslate } from '../services/parser'

const DEFAULTVALUE = `React
has been designed from the start for gradual adoption, and you can use as little or as much React as you need. Whether you want to get a taste of React, add some interactivity to a simple HTML page, or start a complex React-powered app, the links in this section will help you get started.`

const Read = ({ value = DEFAULTVALUE }) => {
    const [linePos, setLinePos] = useState(0)
    const [itemPos, setItemPos] = useState(0)
    const [type, setType] = useState('word')
    const [data, setData] = useState([])

    useEffect(() => {
        let splitter = line => line
        if (type === 'word') splitter = wordSplitter
        if (type === 'ai') splitter = aiSplitter
        setData(parse(value, splitter))
    }, [value, type])

    useEventListener('keydown', (e) => {
        if (e.keyCode === 39) nextPos()
        if (e.keyCode === 37) prevPos()
        if (e.keyCode === 38) prevLine()
        if (e.keyCode === 40) nextLine()
        if (e.key === 'w') changeType('word')
        if (e.key === '/') changeType()
        if (e.key === 'a') changeType('ai')
        if (e.keyCode === 13) gotoGoogleTranslate(data[linePos][itemPos])

        if (37 <= e.keyCode && e.keyCode <= 40) e.preventDefault()
    })

    const changeType = (type) => {
        setType(type)
        setLinePos(0)
        setItemPos(0)
    }

    const prevLine = () => {
        setItemPos(0)
        if (linePos !== 0) setLinePos(linePos - 1)
    }

    const nextLine = () => {
        setItemPos(0)
        if (linePos < data.length - 1) {
            setLinePos(linePos + 1)
        } else {
            setLinePos(0)
        }
    }

    const nextPos = () => {
        const newItemPos = data[linePos].length - 1 <= itemPos ? 0 : itemPos + 1
        let newLinePos = newItemPos === 0 ? linePos + 1 : linePos
        if (data.length <= newLinePos) newLinePos = 0
        setItemPos(newItemPos)
        setLinePos(newLinePos)
    }

    const prevPos = () => {
        if (itemPos === 0) {
            prevLine()
        } else {
            setItemPos(0)
        }
    }

    return (
        <>
            <div className="my-4">
                {data.map((line, lineIndex) => (
                    <div key={lineIndex}>
                        {line.map((item, itemIndex) => {
                            const current = lineIndex===linePos && itemIndex===itemPos
                            return (
                                <span key={itemIndex} style={current ? {} : styleMuted}>
                                    <ItemView value={item} />
                                </span>
                            )}
                        )}
                    </div>
                ))}
            </div>
        </>
    )
}

const ItemView = ({ value }) => {
    return value
}

const styleMuted = {
    color: 'whitesmoke'
}

export default Read
