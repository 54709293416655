import React from 'react'
import PropTypes from 'prop-types';
import {TextField} from '@material-ui/core';

const InputArea = ({ value, onChange, rows = 10 }) => {
    return (
        <>
            <TextField id="outlined-basic" label="英文" variant="outlined" multiline={true} value={value} onChange={e => onChange(e.target.value)} fullWidth rows={rows} />
        </>
    )
}

InputArea.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func
}

export default InputArea
