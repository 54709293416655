import React, { useState, useEffect } from 'react'
// import { Link } from 'gatsby'

import Layout from '../components/layout'
import Help from '../components/help'
import InputArea from '../components/input'
import Read from '../components/read'
import {Paper, Tabs, Tab, Box, IconButton} from '@material-ui/core'
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary'
import EditIcon from '@material-ui/icons/Edit'
import HelpIcon from '@material-ui/icons/Help'
import ShareIcon from '@material-ui/icons/Share';

const IndexPage = () => {
    const [tabValue, setTabValue] = useState(0)
    const [data, setData] = useState()
    const changeHandler = (value) => {
        setData(value)
    }
    const handleChange = (_e, value) => {
        setTabValue(value)
    }

    const [showShareButton, setShowShareButton] = useState(false)
    useEffect(() => {
        setShowShareButton(navigator.share !== undefined)
    }, [])

    const share = () => {
        navigator.share({
            // title: 'Read English',
            // text: 'Read English like native.',
            url: 'http://read-english.exsoft.net/'
        })
    }

    return (
        <Layout>
            <div>
                <div>
                    <Paper square>
                        <Tabs
                            value={tabValue}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handleChange}
                            centered
                        >
                            <Tab label="読む" icon={<LocalLibraryIcon/>} disabled={tabValue===0} />
                            <Tab label="入力する" icon={<EditIcon/>} disabled={tabValue===1} />
                            <Tab label="使い方" icon={<HelpIcon/>} disabled={tabValue===2} />
                        </Tabs>
                    </Paper>
                    <Box p={3}>
                        {tabValue === 1 && <>
                            <InputArea value={data} onChange={changeHandler} rows={20} />
                        </>}
                        {tabValue === 0 && <>
                            <Read value={data} />
                        </>}
                        {tabValue === 2 && <>
                            <Help />
                        </>}
                    </Box>
                </div>
                {showShareButton &&
                <Box my={9} textAlign="right">
                    <IconButton onClick={share}>
                        <ShareIcon />
                    </IconButton>
                </Box>
                }
            </div>
        </Layout>
    )
}

export default IndexPage
